
jQuery(document).ready(function( $ ) {
	/*---[ Back to Top Button Begin ]---*/
    var offset = 220;
    var duration = 2000;
    $(window).scroll(function() {
        if ($(this).scrollTop() > offset) {
            $('.back-to-top').fadeIn(duration);
        } else {
            $('.back-to-top').fadeOut(duration/4);
        }
    });
    
    $('.back-to-top').click(function(event) {
        event.preventDefault();
        $('html,body').animate({scrollTop: 0}, duration/4);
        return false;
    })
	/*---[ Back to Top Button End ]---*/
});

var IsNotInt = function(value) {
	var x;
	if (isNaN(value)) {
		return true;
	}
	x = parseFloat(value);
	return (x | 0) !== x;
}

var EscapeHTML = function(unsafe) {
	return unsafe.replace(/[&<"']/g, function (m) {
		switch (m) {
			case '&':
				return '&amp;';
			case '<':
				return '&lt;';
			case '"':
				return '&quot;';
			default:
				return '&#039;';
		}
	});
};

var RandomAASequence = function (SeqMinLength,SeqMaxLength) {
	var AMINOACIDS = "ARNDCEQGHILKMFPSTWYV";
	var no_of_aas = Math.floor(Math.random() * (SeqMaxLength - SeqMinLength)) + SeqMinLength + 1; //Rando number between Min and Max

	var random_protein = "";
	for (i = 1; i < no_of_aas; i++) {
		random_protein += AMINOACIDS.charAt(Math.floor(Math.random() * 20));
	}
	return random_protein;
}

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function(searchElement, fromIndex) {

      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        // c. Increase k by 1. 
        k++;
      }

      // 8. Return false
      return false;
    }
  });
}